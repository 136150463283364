@import '../base/variables';

.p-checkbox {
  .p-checkbox-box {
    border-color: $checkBoxBorderColor;
    border-style: $checkBoxBorderStyle;
    border-width: $checkBoxBorderWidth;
    cursor: pointer;

    min-height: $checkBoxSize;
    min-width: $checkBoxSize;

    .p-checkbox-icon {
      color: $checkBoxSelectedIconFontColor;
      font-size: $checkBoxSelectedIconFontSize;
      font-weight: $checkBoxSelectedIconFontWeight;
    }

    &:hover {
      border-color: $checkBoxHoverBorderColor;
    }

    // Selected Variation
    &.p-highlight {
      background: $checkBoxSelectedColor;

      &:hover {
        background: $checkBoxSelectedHoverColor;
      }
    }
  }
}
