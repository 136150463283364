@import '../../assets/stylesheets/base/colors';
@import '../../assets/stylesheets/base/typography';

.user-preferences-my-trials-table {
  &.p-datatable {
    td,
    th {
      min-width: 5rem;

      &.bookmark-column {
        max-width: 2rem;
      }

      &.tau-column {
        min-width: 8rem;
      }
    }
  }
}

.total-my-trials {
  @include componentStyle('p');
  color: $gray3;
}
