// sass-lint:disable no-important

.highcharts-label-box {
  fill: none;
}

.highcharts-data-label {
  @include componentStyle('p');
  font-size: 0.9rem;
  font-weight: bold;

  text {
    fill: $white;
  }
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

text {
  &.highcharts-data-label {
    fill: $white;
  }
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-data-label-hidden {
  pointer-events: none;
}
