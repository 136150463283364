// sass-lint:disable no-important
@import '../../../assets/stylesheets/base/colors';

.p-input-icon-left {
  img {
    position: absolute;
    top: 25%;
  }

  .search-submit {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.filter-panel-icon {
  background: transparent;
  border: 0;
  cursor: pointer;

  img {
    height: 14px;
    width: 12px;

    &.reset-icon {
      width: 15px;
    }

    &.view-icon {
      height: 17px;
      width: 17px;
    }
  }
}

.save-filter {
  &.disabled {
    cursor: auto;

    p {
      color: $gray3;
    }

    .save-icon {
      color: $gray3;
    }
  }
}

.filter-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 22px;

  .filter-container {
    height: 233px;
    margin-bottom: 30px;
    width: 211px;

    .filter-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .filter-selected {
        display: flex;
        flex-direction: row;
        gap: 10px;

        p {
          &:nth-of-type(1) {
            color: $gray3;
            font-size: 13px;
          }

          &:nth-of-type(2) {
            border-bottom: 1px solid $gray7;
            text-align: center;
            width: 15px;
          }
        }
      }
    }
  }
}

.checkbox-option {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    cursor: pointer;
  }
}

.filter-body {
  border: 1.5px solid $borderColor;
  // border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 233px;
  padding-right: 4px;
  width: 100%;

  // &::-webkit-scrollbar {
  //   width: 10px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: $gray3;
  //   border-radius: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: $white;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   border: 1px solid $gray3;

  //   &:hover {
  //     background: $white;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     border: 1px solid $gray3;
  //   }
  // }
}

.filter-search {
  border: 1px solid $gray7;
  font-size: 15px;
  font-weight: 400;
  padding-right: 4rem;
  text-align: left;

  &.active {
    border-bottom: 3px solid $gray7;
    margin-bottom: -1px;
  }

  &::placeholder {
    font-style: italic;
  }
}

.radio-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.check-box {
  margin-left: 10px;
  margin-top: 5px;
}

.p-inputField-text {
  height: 25px !important;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 10px;
  width: 90%;
}

.filter-options {
  overflow-y: scroll;
}

.scroll-container {
  // border-radius: 5px;
  overflow-y: auto; /* Enable vertical scroll */
  padding: 10px;
  width: 200px;

  &::-webkit-scrollbar {
    background: $gray4;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $white;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      transparent 35%,
      /* Space above the first line */ $gray2 35%,
      $gray2 39%,
      /* First line (increased height to 4%) */ transparent 39%,
      transparent 50%,
      /* Space between lines */ $gray2 50%,
      $gray2 54%,
      /* Second line (increased height to 4%) */ transparent 54%,
      transparent 65%,
      /* Space between lines */ $gray2 65%,
      $gray2 69%,
      /* Third line (increased height to 4%) */ transparent 69%,
      transparent 80%,
      /* Space between lines */ $gray2 80%,
      $gray2 84%,
      /* Fourth line (increased height to 4%) */ transparent 84%,
      transparent 100% /* Space after the fourth line */
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 30px;
    border: 2px solid $gray4;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $gray4; /* Gray scrollbar track */
  }
}

.greyed-out {
  border-color: $gray11;
  color: $gray3;
  font-weight: bold;
}

.p-checkbox {
  & .p-checkbox-box {
    border-color: $black;
    border-style: solid;
    border-width: 0.5px;
    cursor: pointer;
    font-size: 10px;
    min-height: 1rem !important;
    min-width: 1rem !important;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    .p-checkbox-label {
      color: $gray10;
      cursor: not-allowed;
    }

    & .p-checkbox-box {
      background-color: $gray11;
      border-color: $gray11;

      .p-checkbox-checkmark {
        background-color: $gray11;
      }
    }
  }
}

.checkbox-label {
  font-size: 13px;
  font-weight: 400;
}

.greyed-out-radio {
  color: $gray3;
  cursor: none;
}
