.p-sidebar-mask {
  background-color: $sidebarPopupMaskBackgroundColor;
  margin-top: 5.5rem;

  @media only screen and (min-width: 992px) {
    margin-top: 3rem;
  }

  .p-sidebar {
    background: $sidebarPopupBackgroundColor;
    border: 0;
    padding-bottom: $sidebarPopupPaddingBottom;
    position: relative;
    width: 80%;

    .p-sidebar-header {
      border-bottom: 1px $sidebarPopupHeaderBorderColor solid;
      justify-content: space-between;
      margin-bottom: $sidebarPopupHeaderMargin;
      padding: $sidebarPopupHeaderPadding;

      .p-sidebar-close {
        background: transparent;
        border: 0;
        box-shadow: 0;
        color: $sidebarPopupIconColor;
        font-size: $sidebarPopupCloseIconSize;
        height: $sidebarPopupCloseIconSize;
        transition: color 0.2s;
        width: $sidebarPopupCloseIconSize;

        &:enabled {
          &:hover {
            color: $sidebarPopupIconHoverColor;
          }
        }

        &:focus {
          box-shadow: 0;
          outline: 0;
        }
      }
    }

    .p-sidebar-content {
      padding: 0 1.25rem;
    }

    &.cycle-time-sidebar {
      width: 60%;
    }
  }
}
