@import '../../../base/colors';
@import '../../../base/typography';

$tagBackground: $gray5;

.p-tag {
  @include componentStyle('tag');
  background-color: $tagBackground;
  border-radius: 5000px;
  color: $gray2;
  margin-right: 0.5rem;
  padding: 0.4rem 0.6rem;

  .p-tag-success {
    background-color: $ragGreen;
  }

  .p-tag-info {
    background-color: $tagBackground;
  }

  .p-tag-warning {
    background-color: $ragYellow;
  }

  .p-tag-danger {
    background-color: $ragRed;
  }

  .p-tag-success,
  .p-tag-info,
  .p-tag-warning,
  .p-tag-danger {
    color: $white;
  }

  .p-tag-icon {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-right: 0.25rem;

    &.pi {
      line-height: 1rem;
    }
  }
}
