// sass-lint:disable no-important

$bold: 700;
$semiBold: 600;
$regular: 400;

@mixin componentStyle($component, $isImportant: false) {
  @if $isImportant {
    font-family: Open Sans, sans-serif !important;
  } @else {
    font-family: Open Sans, sans-serif;
  }

  font-style: normal + if($isImportant, !important, null);

  @if ($component == 'h1') {
    font-size: 24px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 32px + if($isImportant, !important, null);
  }
  @if ($component == 'h2') {
    font-size: 20px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 28px + if($isImportant, !important, null);
  }
  @if ($component == 'h3') {
    font-size: 16px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 24px + if($isImportant, !important, null);
  }
  @if ($component == 'h3-reg-weight') {
    font-size: 14px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 24px + if($isImportant, !important, null);
  }
  @if ($component == 'h4') {
    font-size: 14px + if($isImportant, !important, null);
    font-weight: $semiBold + if($isImportant, !important, null);
    line-height: 20px + if($isImportant, !important, null);
  }
  @if ($component == 'btn') {
    font-size: 14px + if($isImportant, !important, null);
    font-weight: $semiBold + if($isImportant, !important, null);
    line-height: 20px + if($isImportant, !important, null);
  }
  @if ($component == 'tag') {
    font-size: 12px + if($isImportant, !important, null);
    font-weight: $semiBold + if($isImportant, !important, null);
    line-height: 16px + if($isImportant, !important, null);
  }
  @if ($component == 'p') {
    font-size: 14px + if($isImportant, !important, null);
    font-weight: $regular + if($isImportant, !important, null);
    line-height: 20px + if($isImportant, !important, null);
  }
  @if ($component == 'sp') {
    font-size: 12px + if($isImportant, !important, null);
    font-weight: $regular + if($isImportant, !important, null);
    line-height: 16px + if($isImportant, !important, null);
  }
  @if ($component == 'link') {
    font-size: 14px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 20px + if($isImportant, !important, null);
  }
  @if ($component == 'cap') {
    font-size: 10px + if($isImportant, !important, null);
    font-weight: $semiBold + if($isImportant, !important, null);
    line-height: 14px + if($isImportant, !important, null);
  }
  @if ($component == 'data') {
    font-size: 32px + if($isImportant, !important, null);
    font-weight: $bold + if($isImportant, !important, null);
    line-height: 42px + if($isImportant, !important, null);
  }
}
