@import '../../base/variables';

.p-card {
  background-color: $cardBackground;

  .p-card-header {
    border-bottom: 1px solid $gray4;
    padding: $cardSectionPadding;
  }

  .p-card-content {
    padding: $cardSectionPadding;
    padding-bottom: 0;
  }

  .p-card-footer {
    padding: $cardFooterPadding;
  }
}
