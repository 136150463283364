// sass-lint:disable no-important
@import '../base/colors';

.cursor-pointer {
  cursor: pointer;
}

.hover-red-border {
  @media (hover: hover) {
    &:hover {
      border-color: $takedaRed !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
