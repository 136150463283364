@import '../base/variables';
@import '../../../base/typography';

.p-chip {
  @include componentStyle('p');
  background-color: $chipBackground;
  border: 1px solid $chipBackground;
  border-radius: $chipBorderRadius;
  color: $chipTextColor;
  cursor: pointer;
  padding: $chipPadding;

  .p-chip-text {
    margin: $chipTextMargin;
  }

  .p-chip-icon {
    margin: $chipImageOrIconMargin;
  }

  img {
    margin: $chipImageOrIconMargin;
  }

  .p-chip-remove-icon {
    border-radius: $chipRemoveIconBorderRadius;
    margin-left: $chipRemoveIconMargin;

    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }
}

.selected-filter {
  background-color: $selectedFilterChipBackground;
  border: 1px solid $selectedFilterChipBorderColor;
  border-radius: $chipBorderRadius;
}
