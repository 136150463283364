@import '../../base/variables';

.p-card {
  &.side-card {
    border: 1px solid $gray4;
    margin-bottom: 0.5rem;
    padding: 0.5rem;

    .gray-bottom {
      border-bottom: 1px solid $gray4;
    }

    .p-card-header {
      border: 0;
      padding: 0;
    }

    .p-card-content {
      padding: 0;
    }

    .p-card-footer {
      padding: 0;
    }
  }
}
