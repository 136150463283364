@import '../../base/variables';

.p-card {
  &.boxed-card {
    background: $gray6;
    padding: 0.75rem;

    .p-card-header {
      border: 0;
      padding: 0;
    }

    .p-card-content {
      padding: 0;
    }
  }
}
