@import '../../../base/colors';

@keyframes p-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes p-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.p-progress-spinner {
  height: 1rem;
  margin: 0;
  margin-right: 0.2rem;
  margin-top: 0.1rem;
  position: relative;
  width: 1rem;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.p-progress-spinner-svg {
  animation: p-progress-spinner-rotate 2s linear infinite;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center center;
}

.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: $gray3;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}
