// sass-lint:disable no-color-literals
// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@import '../base/colors';

.nav-menu-container {
  background: $white;
  border-bottom: 1px solid $gray5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .nav-prefix {
    max-width: 10%;

    img {
      width: 1.5rem;
    }
  }

  a {
    color: $gray1;
  }

  .nav-menu {
    column-gap: 0.25rem;
    padding-top: 0.2rem;

    h4 {
      @include componentStyle('h4');
      align-items: center;
      color: $gray1;
      cursor: pointer;
      display: flex;
      margin: 0;
      padding: 0.7rem 0.75rem;

      &.active {
        box-shadow: inset 0 -4px $takedaRed;
      }

      &.open {
        box-shadow: inset 0 -4px $takedaRed;
        color: $takedaRed;
      }

      @media (hover: hover) {
        &:hover {
          color: $takedaRed;
        }
      }
    }
  }
}

.nav-menu-dropdown {
  background-color: $white;
  padding: 0.75rem 0;

  .p-menuitem {
    @include componentStyle('h4');

    a {
      color: $gray1;
      padding: 0.75rem 1rem;
    }

    &.active {
      a {
        color: $takedaRed;
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: $gray5;
      }
    }
  }
}
