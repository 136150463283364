.display-measurement {
  &.main-data {
    .magnitude {
      @include componentStyle('data');
    }

    .unit {
      @include componentStyle('p');

      padding-bottom: 0.3rem;
    }
  }

  &.body-data {
    .magnitude {
      @include componentStyle('h1');
    }

    .unit {
      @include componentStyle('p');

      padding-bottom: 0.3rem;
    }
  }

  &.subordinate {
    @include componentStyle('sp');
  }

  &.large-data {
    .magnitude {
      @include componentStyle('data');
    }

    .unit {
      @include componentStyle('data');

      padding-bottom: 0.3rem;
    }
  }

  &.bold-data {
    .magnitude {
      @include componentStyle('data');
    }

    .unit {
      @include componentStyle('h3');
      padding-bottom: 0.1rem;
    }
  }
}
