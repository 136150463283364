// sass-lint:disable no-important

.highcharts-contextbutton {
  fill: $white;
  stroke: none;

  &:hover {
    fill: $gray6;
  }

  .highcharts-button-box {
    stroke: $gray4 !important;
  }
}

.highcharts-button-symbol {
  stroke: $gray2 !important;
  stroke-width: 2px;
}

.highcharts-menu {
  background: $white;
  border: 1px solid $gray4 !important;
  box-shadow: 1px 1px 5px $gray2 !important;
  padding: 5px 0;
}

.highcharts-menu-item {
  @include componentStyle('p', true);

  background: none;
  color: $gray1 !important;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background 250ms, color 250ms;

  &:hover {
    background: $gray4 !important;
  }
}
