@import '../base/variables';

.p-progressbar {
  background: transparent;
  border: 0 none;
  height: 1.5rem;

  .p-progressbar-value {
    background: $takedaRed;
    border: 0 none;
    margin: 0;
  }

  .p-progressbar-label {
    color: $white;
    line-height: 1.5rem;

    .align-right {
      padding-right: 0.18rem;
      text-align: right;
    }
  }
}
