.p-dialog-header-close {
  margin: 1rem;
}

.p-dialog-enter-done {
  background-color: $dialogBackground;
  box-shadow: $dialogMaskBackgroundColor;
}

.p-dialog-header {
  height: 0;
}

.p-dialog {
  .p-button {
    width: auto;
  }
}
