.confirmation-dialog {
  width: 30vw;
}

.loader-container {
  align-items: center;
  display: flex;
  height: 70%;
  justify-content: center;
  overflow: hidden; // Ensure no scrollbars
  padding-top: 25px;
  text-align: center;
  width: 100%;
}
