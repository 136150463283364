// sass-lint:disable no-important

.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  transition: stroke 150ms;
  white-space: nowrap;

  text {
    @include componentStyle('sp', true);

    color: $gray2 !important;
    fill: $gray2 !important;
  }

  .highcharts-header {
    font-size: 0.85rem;
  }
}

.highcharts-tooltip-box {
  fill: $white !important;
  fill-opacity: 0.85;
  stroke: $gray2;
  stroke-width: 1;

  .highcharts-label-box {
    fill: $white !important;
    fill-opacity: 0.85;
  }
}

div {
  &.highcharts-tooltip {
    filter: none;
  }
}
