@import '../base/variables';
@import '../../../base/colors';
@import '../../../base/typography';

.p-tabview {
  .p-tabview-nav-container {
    border-bottom: 1px solid $gray3;
  }

  .p-tabview-nav-link {
    @include componentStyle('btn');
    color: $gray1;
  }

  .p-tabview-selected {
    .p-tabview-nav-link {
      border-bottom: 2px solid $takedaRed;
      color: $takedaRed;
    }
  }

  .p-tabview-title {
    padding: $tabViewHeaderPadding;
  }
}
