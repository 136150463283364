@import '../base/variables';

.p-inputswitch {
  height: $isSliderHeight;
  width: $isSliderWidth;

  .p-inputswitch-slider {
    background: $isSliderBackgroundColor;
    border: $isSliderBorder;
    border-radius: $isSliderBorderRadius;

    &:before {
      background: $isSliderSwitchColor;
      border-radius: $isSliderBorderRadius;
      height: $isSliderSwitchHeight;
      margin: $isSliderSwitchMargin;
      width: $isSliderSwitchWidth;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $isSliderActiveBackgroundColor;
      border: $isSliderActiveBorder;

      &:before {
        background: $isSliderSwitchColor;
        transform: translateX($isSliderSwitchTranslateLength);
      }
    }
  }
}

.p-inputswitch-slider {
  &:before {
    content: '';
    position: absolute;
    top: 0%;
  }
}

.p-disabled {
  .p-inputswitch-slider {
    background: $disabledToggle;

    &::before {
      background: $disabledToggleSlider;
    }
  }
}
