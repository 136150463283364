@import '../../../base/typography';
@import '../../../base/colors';

.p-badge {
  @include componentStyle('tag');

  background: $aqua;
  color: $white;

  &.p-badge-secondary {
    background-color: $takedaRed;
    color: $white;
  }

  &.p-badge-success {
    background-color: $ragGreen;
    color: $white;
  }

  &.p-badge-info {
    background-color: $darkAqua;
    color: $white;
  }

  &.p-badge-warning {
    background-color: $ragYellow;
    color: $white;
  }

  &.p-badge-danger {
    background-color: $ragRed;
    color: $white;
  }

  &.p-badge-lg {
    @include componentStyle('p');
  }

  &.p-badge-xl {
    @include componentStyle('h3');
  }
}
