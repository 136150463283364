// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
@import '../base/colors';

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: $takedaRed;
  text-decoration: none;
}

body {
  background: $gray6;
}
