@import '../../assets/stylesheets/base/colors';

.delete-dialog-main {
  flex-shrink: 0;
  height: 210px;
  width: 743px;
}

.button-section-details {
  align-items: center;
  display: inline-flex;
  gap: 0.75rem;

  .cancel-btn {
    align-items: center;
    background: $white;
    border: 1px solid $takedaRed;
    color: $takedaRed;
    cursor: pointer;
    display: flex;
    font-family: Open Sans, sans-serif;
    gap: 0.625rem;
    height: 2rem;
    justify-content: center;
    padding: 0.625rem;
    width: 7.5rem;
  }

  .confirm-btn {
    align-items: center;
    background: $takedaRed;
    color: $white;
    cursor: pointer;
    display: flex;
    font-family: Open Sans, sans-serif;
    gap: 0.625rem;
    height: 2rem;
    justify-content: center;
    padding: 0.625rem;
    width: 7.5rem;
  }
}

.main-header {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 32px 67px 0 32px;

  .sub-header-delete {
    color: $gray1;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 36px;

    span {
      color: $gray1;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
    }
  }

  .header {
    color: $gray1;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
}
