@import '../base/variables';
@import '../../../base/typography';

.p-selectbutton {
  display: inline;

  .p-buttonset {
    &.p-button {
      &:not(:last-child),
      &:not(:first-of-type) {
        border: inherit;
      }

      &:not(:last-of-type) {
        border: inherit;
      }
    }
  }

  .p-button {
    background: $sbBackgroundColor;
    border: $sbBorder;
    border-radius: $sbBorderRadius;
    box-sizing: border-box;
    color: $sbContentColor;
    height: $sbHeight;
    padding: $sbContentPadding;

    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          background: $sbBackgroundColor;
          border: $sbOtherHoverBorder;
          color: $sbHoverTextColor;
        }
      }
    }

    :hover {
      background: 0;
      border: 0;
      color: $sbHoverTextColor;
    }

    &.p-highlight {
      background: $sbActiveBackgroundColor;
      border: 0;
      color: $sbActiveContentColor;

      &:hover {
        background: $sbActiveHoverBackground;
      }

      .p-button-label {
        &:hover {
          color: $sbActiveContentColor;
        }
      }
    }
  }

  .p-button-label {
    @include componentStyle('p');
  }

  &.skip-default-background {
    .p-button {
      background: transparent;
      border: 0;
      border-radius: 0;
      box-sizing: border-box;
      color: $gray2;
      padding: 0.05rem;

      &:not(.p-disabled) {
        &:not(.p-highlight) {
          &:hover {
            background: transparent;
            border: 0;
            color: $gray2;
          }
        }
      }

      :hover {
        background: transparent;
        border: 0;
        color: $gray2;
      }

      &.p-highlight {
        background: transparent;
        border: 0;
        color: $gray1;

        &:hover {
          background: transparent;
        }

        .p-button-label {
          @include componentStyle('tag');

          &:hover {
            color: $gray1;
          }
        }
      }
    }

    .p-button-label {
      @include componentStyle('sp');
    }

    :not(:last-child) {
      &::after {
        align-items: flex-end;
        color: $gray2;
        content: '|';
        display: flex;
        height: 1rem;
        padding: 0 0.05rem;
      }
    }
  }
}
