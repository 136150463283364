@import '../../../base/colors';
@import '../../../base/typography';

/*
***** React Prime Variables *****

1. Generic Variables
2. 
3. Forms Components
  3.1 SelectButton
  3.2 InputSwitch
  3.3 Checkbox
  3.4 InputText
4.
5. Other Components
  5.1 Button
  5.2 Datatable
  5.3 Datatable paginator
  5.4 Filter Chip
  5.5 Card
  5.6 Tooltip
  5.7 DatePicker
  5.8 Trial Overview - Card View
  5.10 Dialog
  5.11 Toaster
*/
$simpleBorder: 1px solid;
$componentWhite: $white;
//3.1 Select Button
$sbBackgroundColor: $componentWhite;
$sbActiveBackgroundColor: $takedaRed;
$sbActiveContentColor: $componentWhite;
$sbBorderWidth: 1px;
$sbBorderColor: $gray4;
$sbBorder: $sbBorderWidth solid $sbBorderColor;
$sbContentColor: $gray2;
$sbBorderRadius: 0;
$sbHeight: 2rem;
$sbContentPadding: 0.5rem 2rem;
$sbHoverTextColor: $takedaRed;
$sbOtherHoverBorder: $sbBorderWidth solid $sbHoverTextColor;
$sbActiveHoverBackground: $takedaRedHover;

//3.2 Forms InputSwitch
$isSliderActiveBackgroundColor: $takedaRed;
$isSliderBackgroundColor: $gray2;
$isSliderSwitchColor: $componentWhite;
$isSliderBorderWidth: 0.0625rem;
$isSliderBorderColor: $gray2;
$isSliderActiveBorderColor: $takedaRed;
$isSliderBorderRadius: 1rem;
$isSliderSwitchWidth: 1rem;
$isSliderSwitchHeight: 1rem;
$isSliderHeight: 1.25rem;
$isSliderWidth: 2.5rem;
$isSliderSwitchMargin: 0.075rem 0 0 0.075rem;
$isSliderSwitchTranslateLength: 1.25rem;
$isSliderBorder: $isSliderBorderWidth solid $isSliderBorderColor;
$isSliderActiveBorder: $isSliderBorderWidth solid $isSliderActiveBorderColor;
$disabledToggle: $gray3;
$disabledToggleSlider: $gray4;

// 3.3 Forms Checkbox
$checkBoxSize: 1.25rem;
$checkBoxBorderWidth: 1px;
$checkBoxBorderStyle: solid;
$checkBoxBorderColor: $takedaRed;
$checkBoxHoverBorderColor: $takedaRedHover;

$checkBoxSelectedColor: $takedaRed;
$checkBoxSelectedIconFontColor: $componentWhite;
$checkBoxSelectedIconFontSize: 0.8rem;
$checkBoxSelectedIconFontWeight: 900;
$checkBoxSelectedHoverColor: $takedaRedHover;

//3.4 InputText
$inputTextFieldColor: $gray3;
$inputBorder: $simpleBorder $inputTextFieldColor;
$inputFocusBorder: $simpleBorder $takedaRed;
$inputHeight: 2rem;

//5.2 Datatable
$dtCellBackgroundColor: $white;
$dtHeaderContentColor: $gray2;
$dtCellPadding: 0.875rem 0 0.875rem 1rem;
$dtSortIconColor: $black;
$dtSortIconLeftMargin: 0.375rem;
$dtCellBorderColor: $gray3;
$dtCellBorderWidth: 0.0625rem;
$dtCellBottomBorder: 0 0 0.0625rem 0;
$dtBodyTextColor: $gray1;
$dtRowHoverBackground: $gray5;
$dtHeaderBordercolor: rgba($black, 0.5);
$dtRowGrayedColor: $gray6;
$dtHeaderBoxShadow: rgba($black, 0.15);
$bodyRowItemsBorderColor: $gray4;

// 5.3 Datatable paginator
$paginatorActiveButtonBackground: $takedaRed;
$paginatorActiveButtonFontColor: $componentWhite;
$paginatorButtonsBackground: $componentWhite;
$paginatorButtonsTextColor: $gray3;
$paginatorButtonPadding: 0.5rem 1rem;
$paginatorButtonMaxWidth: 10px;
$noBorderRadius: 0;
$paginatorMargin: 1rem;

//5.4 Filter Chip
$chipPadding: 0 0.75rem;
$chipTextColor: $gray2;
$chipTextMargin: 0.375rem 0 0.375rem 0;
$chipBorderRadius: 1rem;
$chipImageOrIconMargin: 0 0.5rem 0 0;
$chipRemoveIconBorderRadius: 6px;
$chipRemoveIconMargin: 0 0 0 0.5rem;
$chipBackground: $gray5;
$notSelectedFilterChipBackground: $gray5;
$selectedFilterChipBackground: $componentWhite;
$selectedFilterChipBorderColor: $chipTextColor;

//5.5 Card
$cardBackground: $componentWhite;
$cardSectionBorderColor: $gray4;
$cardFooterAndHeaderBorder: $simpleBorder $cardSectionBorderColor;
$cardShadow1: 0 0 0 0 rgba(0, 0, 0, 0.2);
$cardShadow2: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$cardShadow3: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
$cardShadow: $cardShadow1, $cardShadow2, $cardShadow3;
$cardSectionPadding: 1rem 1.5rem;
$cardFooterPadding: 1rem 1.5rem;
$cardSpanImageWidth: 0.625rem;

//5.6 Sidebar Popup
$sidebarPopupIconColor: $black;
$sidebarPopupIconHoverColor: $takedaRed;
$sidebarPopupHeaderBorderColor: $gray5;
$sidebarPopupBackgroundColor: $componentWhite;
$sidebarPopupContentPadding: 1.25rem;
$sidebarPopupHeaderPadding: 1rem 1.5rem;
$sidebarPopupHeaderMargin: 1.5rem;
$sidebarPopupCloseIconSize: 1rem;
//TODO: ADD opacity instead of opacity color
$sidebarPopupMaskBackgroundColor: rgba($gray1, 0.2);
$sidebarPopupPaddingBottom: 2rem;

//5.7 tooltip
$tooltipBackgroundColor: $componentWhite;
$tooltipWidth: 13.125rem;
$tooltipShadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 12%);
$tooltipBorder: 0.0625rem solid $gray3;
$tooltipTextColor: $gray3;
$tooltipTextPadding: 0.75rem 0.8125rem;

//5.8 Datepicker
$datepickerBoxBorder: $simpleBorder transparent;
$datepickerHeaderPadding: 0.3rem 0;
$datepickerDaysColor: $gray2;
$solidWhiteBorder: $simpleBorder $componentWhite;
$monthAndYearPickerBorder: $simpleBorder $gray4;
$calendarInputBoxBorder: $simpleBorder $gray5;
$datepickerButtonBarBorderColor: $gray4;
$datepickerHoverTextColor: $takedaRed;
$datepickerMonthYearHoverBorder: 1px solid $datepickerHoverTextColor;

// 5.9 Trial Overview - Card View
$trialKeyOverviewRagWidthHeight: 2rem;
$trialKeyOverviewBackground: $gray5;
$trialKeyOverviewCardGeneralSpacing: 0.75rem;
$trialKeyOverviewCardTextColor: $gray2;
$trialKeyOverviewCardHoverColor: $gray3;

// 5.10 Dialog
$dialogBackground: $componentWhite;
$dialogMaskBackgroundColor: 0 0 0 9999px rgba($gray2, 0.2);

//5.11 Toaster
$successToastBackground: $ragGreen40;
$errorToastBackground: $takedaRed;

// Tab View
$tabViewHeaderPadding: 0.625rem;

//ShadowForEdc
$dtCellShadow: 5px 0 5px 0 rgba(0, 0, 0, 0.1);
