// // Import Bootstrap and its default variables

@import 'primereact/resources/primereact.css'; //core css
@import 'primeicons/primeicons.css'; //icons

// Do not un comment, for refer. purposes
// @import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme

@import './reactPrime/theme';

// High Chart SCSS
@import './highcharts/theme';

.highcharts-credits {
  display: none;
}
