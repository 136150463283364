// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  @include componentStyle('h1');

  &.placeholder {
    color: $gray3;
  }
}

h2 {
  @include componentStyle('h2');

  &.placeholder {
    color: $gray3;
  }
}

h3 {
  @include componentStyle('h3');

  &.placeholder {
    color: $gray3;
  }
}

h4 {
  @include componentStyle('h4');

  &.placeholder {
    color: $gray3;
  }
}

.sp {
  @include componentStyle('sp');
}

p {
  @include componentStyle('p');
  margin: 0;
  padding: 0;

  &.placeholder {
    color: $gray3;
  }
}

span {
  &.placeholder {
    color: $gray3;
  }
}

.display-measurement,
.trend-measurement {
  img {
    margin-right: 0.2rem;
  }

  &.unboxed {
    &.magnitude-positive,
    &.magnitude-zero {
      color: $ragGreen;
    }

    &.magnitude-negative {
      color: $ragRed;
    }
  }

  &.boxed {
    @include componentStyle('tag');
    background-color: $gray3;
    color: $white;
    height: fit-content;
    padding: 0.3rem;
    width: fit-content;

    .placeholder {
      color: $white;
    }

    &.magnitude-positive,
    &.magnitude-zero {
      background-color: $ragGreen;
      color: $white;
    }

    &.magnitude-negative {
      background-color: $ragRed;
      color: $white;
    }
  }

  &.trend {
    &-red {
      color: $ragRed;
    }

    &-green {
      color: $ragGreen;
    }

    &-gray {
      color: $gray3;
    }
  }
}
