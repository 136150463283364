@import '../base/variables';

.p-inputtext {
  @include componentStyle('p');
  border: $inputBorder;
  border-radius: 0;
  height: $inputHeight;
  line-height: 16px;
  outline: transparent;
  width: 100%;

  &.p-inputtextarea {
    height: initial;
    resize: none;
  }

  &:focus {
    border: $inputFocusBorder;
  }
}
