@import '../base/variables';

.p-calendar {
  outline: $calendarInputBoxBorder;

  &.p-inputwrapper {
    .p-inputtext {
      border: 0;
      outline: none;
      padding: 0.35rem;
      width: 100%;
    }
  }

  .p-button {
    &.p-button-icon-only {
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      padding-right: 0.5rem;

      span {
        color: $black;

        &:hover {
          background-color: transparent;
          border: 0;
        }
      }
    }
  }
}

.p-datepicker-calendar-container {
  height: auto;
  width: auto;
}
