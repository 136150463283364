@import '../../../../../assets/stylesheets/base/colors';
@import '../base/variables';

.p-dropdown {
  background: $white;
  border: 0.0625rem solid $gray3;
  height: $inputHeight;
  padding: 0.375rem 0.75rem;

  &:not(.p-disabled) {
    &:hover {
      border: 0.0625rem solid $takedaRed;
    }
  }

  &.p-disabled {
    background: $gray6;
    color: $gray3;
  }
  // &:not(.p-disabled).p-focus {
  // 	outline: 0 none;
  // 	outline-offset: 0;
  // 	box-shadow: 0 0 0 0.2rem #C7D2FE;
  // 	border-color: #26873a;
  // }

  .p-dropdown-label {
    border: 0 none;
    outline: 0;

    &:enabled {
      &:focus {
        box-shadow: none;
        outline: 0 none;
      }
    }
  }

  .p-dropdown-trigger {
    background: transparent;
    padding-left: 0.5rem;
  }
}

.p-dropdown-panel {
  border: 0.0625rem solid $gray3;
  margin-top: 1px;
}

.p-dropdown-items {
  background: $white;

  .p-dropdown-item {
    @include componentStyle($component: 'p');
    color: $gray1;
    padding: 0.625rem;
    transition: box-shadow 0.2s;

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          background: $gray4;
        }
      }
    }
  }
}
