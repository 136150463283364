@import '../../base/variables';

.p-card {
  &.sub-card {
    border: 1px solid $gray4;

    &.active {
      border-bottom: 4px solid $takedaRed;
    }

    &.hover {
      &:hover {
        background-color: $gray6;
        cursor: pointer;
      }
    }

    .p-card-header {
      border: 0;
      padding: 0.5rem 1rem;

      img {
        width: fit-content;
      }
    }

    .p-card-content {
      padding: 0.5rem 1rem;
    }

    .p-card-footer {
      padding: 0.5rem 1rem;
    }
  }
}
