@import '../../../base/typography';

@mixin activeMenu {
  color: $takedaRed;
}

.p-menubar {
  .p-menuitem {
    .p-menuitem-link {
      @include componentStyle('h4');
      border-bottom: 4px solid transparent;
      cursor: pointer;
      padding: 0.7rem 1rem;

      &:hover {
        @include activeMenu;
      }
    }

    &.p-menuitem-active {
      .p-menuitem-link {
        &:nth-child(1) {
          @include componentStyle('h4');
          border-bottom: 4px solid $takedaRed;
        }
      }
    }

    .p-submenu-list {
      background-color: $white;
      width: 100%;

      .p-menuitem {
        &.p-menuitem-active {
          :first-child {
            @include activeMenu;
          }
        }

        .p-menuitem-text {
          @include componentStyle('h4');
        }

        .p-menuitem-link {
          border: 0;
        }
      }
    }
  }
}
