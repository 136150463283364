@import '../base/variables';

.p-tooltip {
  background-color: $tooltipBackgroundColor;
  border: $tooltipBorder;
  box-shadow: $tooltipShadow;
  width: $tooltipWidth;

  .p-tooltip-text {
    background: $tooltipBackgroundColor;
    color: $tooltipTextColor;
    padding: $tooltipTextPadding;
  }
}
