@import '../../../base/typography';
@import '../../../base/colors';

.p-button {
  @include componentStyle('btn');
  background: $takedaRed;
  border: 1px solid $takedaRed;
  color: $white;
  margin: 1rem 1rem 0 0;
  padding: 0.5rem 3rem;

  &:enabled {
    &:hover,
    :active {
      background: $takedaRedHover;
      border: 1px solid $takedaRedHover;
      color: $white;
    }
  }

  &.p-disabled,
  :hover,
  :active {
    background: $gray3;
    border: 1px solid $gray3;
    color: $white;
  }

  &.p-button-secondary {
    background-color: $white;
    border: 1px solid $takedaRed;
    color: $takedaRed;

    &:hover {
      background-color: $takedaRed20;
      border: 1px solid $takedaRed;
    }

    .p-button-label {
      background-color: transparent;
      color: $takedaRed;

      &:hover {
        background-color: transparent;
        border: 0;
        color: $takedaRed;
      }
    }
  }

  &.p-button-outlined {
    background-color: transparent;
    border: 1px solid $takedaRed;
    color: $takedaRed;

    &:enabled {
      &:hover,
      :active {
        background: $takedaRed;
        border: 1px solid;
        color: $white;
      }
    }

    &.p-disabled,
    :hover,
    :active {
      background-color: transparent;
      border: 1px solid $gray3;
      color: $gray3;
    }
  }

  &.p-button-borderless {
    background-color: transparent;
    border: 0;
    color: $gray1;
    padding: 0;

    &:enabled {
      &:hover,
      :active {
        background: transparent;
        border: 0;
        color: $gray2;
      }
    }
  }

  .p-button-label {
    background-color: inherit;
    border: 0;
    color: inherit;
  }
}
