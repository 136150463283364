// sass-lint:disable variable-name-format no-important no-combinators
@import '../../../base/colors';
@import '../../../base/typography';

/* Bubble legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item {
  > text {
    cursor: pointer;
    fill: $gray3;
    font-size: 200px;
    font-weight: bold;
    stroke-width: 0;
  }

  &:hover {
    text {
      fill: $gray3;
    }
  }
}

.highcharts-legend-item-hidden {
  * {
    fill: $gray4 !important;
    stroke: $gray4 !important;
    transition: fill 250ms;
  }
}

.highcharts-legend-nav-active {
  cursor: pointer;
  fill: $gray3;
}

.highcharts-legend-nav-inactive {
  fill: $gray3;
}

circle {
  &.highcharts-legend-nav-active {
    fill: $white;
  }

  &.highcharts-legend-nav-inactive {
    fill: $white;
  }

  &.highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
  }
}

.highcharts-bubble-legend-symbol {
  fill-opacity: 0.5;
  stroke-width: 2;
}

.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}

.highcharts-bubble-legend-labels {
  fill: $gray3;
}
