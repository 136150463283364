// sass-lint:disable max-file-line-count
@import '../base/variables';

.p-datatable {
  @for $i from 1 through 30 {
    .cell-min-w-#{$i} {
      min-width: clamp(#{$i}rem, #{$i * 2}rem, #{$i * 4}rem);
    }

    .cell-max-w-#{$i} {
      max-width: clamp(#{$i}rem, #{$i * 2}rem, #{$i * 4}rem);
    }

    .cell-w-#{$i} {
      text-wrap: wrap;
      width: clamp(#{$i}rem, #{$i * 2}rem, #{$i * 4}rem);
    }
  }

  &.p-datatable-scrollable {
    .p-datatable-wrapper {
      .p-datatable-table {
        .cell-width-filter-8 {
          max-width: 8.5rem;
          min-width: 8.5rem;
        }

        .cell-width-filter-10 {
          max-width: 10rem;
          min-width: 10rem;
        }

        .p-datatable-thead {
          background-color: $newBackground;

          .pi-filter {
            border-left: 1px solid $gray3;
            font-size: 0.75rem;
            padding-left: 1rem;
            vertical-align: bottom;
          }
        }

        .p-datatable-tfoot {
          background-color: $newBackground;
        }
      }
    }
  }

  .p-datatable-header {
    border-bottom: 1px solid $dtHeaderBordercolor;
    color: $gray2;
    font-weight: 700;
    padding: 1rem;
  }

  .p-datatable-footer {
    border-width: 0 0 1px;
    color: $gray2;
    font-weight: 700;
    padding: 1rem;
  }

  .p-datatable-thead {
    tr {
      th {
        @include componentStyle($component: 'h4');
        background: $dtCellBackgroundColor;
        border: $dtCellBorderWidth solid $black;
        border-width: $dtCellBottomBorder;
        color: $dtHeaderContentColor;
        padding: $dtCellPadding;
        text-align: left;
        transition: box-shadow 0.2s;
      }

      .p-filter-column {
        display: none;
      }

      .p-selection-column {
        display: none;
      }
    }
  }

  .p-datatable-tfoot {
    tr {
      td {
        background: $white;
        border: $dtCellBorderWidth solid $dtCellBorderColor;
        border-width: $dtCellBottomBorder;
        color: $gray2;
        font-weight: 700;
        padding: 1rem;
        text-align: left;
      }
    }
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: transparent;
      margin-left: $dtSortIconLeftMargin;

      &::before {
        background-image: url('../../../../icons/sort.svg');
      }
    }

    .pi-sort-amount-up-alt {
      &::before {
        background-image: url('../../../../icons/sortUp.svg');
      }
    }

    .pi-sort-amount-down {
      &::before {
        background-image: url('../../../../icons/sortDown.svg');
      }
    }

    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      line-height: 1.143rem;
      margin-left: 0.5rem;
      min-width: 1.143rem;
    }
  }

  .p-datatable-tbody {
    tr {
      background: $dtCellBackgroundColor;
      color: $dtBodyTextColor;

      &.p-highlighted-row {
        background-color: $dtRowGrayedColor;
      }

      &.forecast-highlight {
        background-color: $aqua10;
      }

      td {
        @include componentStyle($component: 'p');
        border: 1px solid $gray4;
        border-width: 0 0 1px;
        padding: $dtCellPadding;
        text-align: left;

        .p-row-editor-save {
          background: transparent;
          border: 0 none;
          border-radius: 50%;
          height: 2rem;
          margin-right: 0.5rem;
          width: 2rem;

          &:enabled {
            &:hover {
              border-color: transparent;
            }
          }

          &:focus {
            outline: 0 none;
            outline-offset: 0;
          }
        }

        .p-row-editor-cancel {
          background: transparent;
          border: 0 none;
          border-radius: 50%;
          height: 2rem;
          width: 2rem;

          &:enabled {
            &:hover {
              border-color: transparent;
            }
          }

          &:focus {
            outline: 0 none;
            outline-offset: 0;
          }
        }

        .p-column-title {
          font-weight: 700;
        }
      }

      .p-selection-column {
        display: none;
      }
    }
  }

  .p-datatable-loading-icon {
    color: $gray3;
    font-size: 2rem;
  }

  &.p-datatable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0;
    }

    .p-datatable-footer {
      border-width: 0 1px;
    }

    .p-paginator-top {
      border-width: 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0 1px 1px;
    }

    .p-datatable-thead {
      tr {
        th {
          border-width: 1px;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        td {
          border-width: 1px;
        }
      }
    }

    .p-datatable-tfoot {
      tr {
        td {
          border-width: 1px;
        }
      }
    }
  }

  &.p-datatable-sm {
    .p-datatable-header {
      padding: $dtCellPadding;
    }

    .p-datatable-thead {
      tr {
        th {
          padding: $dtCellPadding;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        td {
          padding: $dtCellPadding;
        }
      }
    }

    .p-datatable-tfoot {
      tr {
        td {
          padding: $dtCellPadding;
        }
      }
    }

    .p-datatable-footer {
      padding: $dtCellPadding;
    }
  }

  &.p-datatable-lg {
    .p-datatable-header {
      padding: 1.25rem;
    }

    .p-datatable-thead {
      tr {
        th {
          padding: 1.25rem;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        td {
          padding: 1.25rem;
        }
      }
    }

    .p-datatable-tfoot {
      tr {
        td {
          padding: 1.25rem;
        }
      }
    }

    .p-datatable-footer {
      padding: 1.25rem;
    }
  }
}

.clickable-row-datatable {
  .p-datatable-tbody {
    tr {
      &:not(.disable-hover) {
        &:hover {
          background-color: $dtRowHoverBackground;
          cursor: pointer;
        }
      }
    }
  }
}

.p-treetable {
  &.p-treetable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0;
    }
  }
}
