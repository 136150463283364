@import '../../../base/colors';
@import '../../../base/typography';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.p-toast {
  // sass-lint:disable mixin-name-format
  @include media-breakpoint-up(lg) {
    margin-top: 5%;
  }

  @include componentStyle('p');
  margin-top: 10%;

  .p-toast-message-content {
    color: $white;
    padding: 10px;
  }

  .p-toast-message-text {
    padding-left: 5px;
  }

  .p-toast-message-success {
    background-color: $successToastBackground;
  }

  .p-toast-message-error {
    background-color: $errorToastBackground;
  }

  .p-toast-message-icon {
    display: none;
  }
}
