// sass-lint:disable variable-name-format no-important

.highcharts-axis-line {
  fill: none;
  stroke: $gray2;
  stroke-width: 2;
}

.highcharts-yaxis {
  .highcharts-axis-line {
    stroke-width: 0;
  }

  .highcharts-tick {
    stroke-width: 0;
  }
}

.highcharts-axis-title {
  @include componentStyle('tag', true);

  color: $gray2 !important;
  fill: $gray2 !important;
}

.highcharts-axis-labels {
  @include componentStyle('sp', true);

  color: $gray2 !important;
  cursor: default;
  fill: $gray2 !important;

  text {
    @include componentStyle('sp', true);

    color: $gray2 !important;
    fill: $gray2 !important;
  }
}

.highcharts-grid-line {
  fill: none;
  stroke: $gray4;
}

.highcharts-xaxis-grid {
  .highcharts-grid-line {
    stroke-width: 0;
  }
}

.highcharts-tick {
  stroke: $gray4;
}

.highcharts-minor-grid-line {
  stroke: $gray4;
}

.highcharts-crosshair-thin {
  stroke: $gray4;
  stroke-width: 1px;
}

.highcharts-crosshair-category {
  stroke: $gray4;
  stroke-opacity: 0.25;
}
