@import '../../assets/stylesheets/base/colors';
@import '../../assets/stylesheets/base/typography';

.user-pref-menu {
  h2 {
    cursor: pointer;
    margin: 0;
    margin-bottom: -1rem;
    padding: 0.7rem 1rem;

    &.active {
      border-bottom: 4px solid $takedaRed;
    }
  }
}

.user-pref-sidebar {
  &.p-sidebar-mask {
    .p-sidebar {
      .p-sidebar-content {
        padding-bottom: 3rem;
      }
    }
  }
}
