@import '../base/colors';

.highlight-chat-journey {
  &::before {
    background: $chatJourneyHighlightBackground2;
    border: 1px solid $chatJourneyHighlightBorder;
    border-radius: inherit;
    box-shadow: 0 0 12px $chatJourneyHighlightRadiant;
    content: '';

    height: 100%;
    left: 0;
    outline: 2px solid $chatJourneyHighlightBorder;

    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10004;
  }

  &.circle-highlight {
    &::before {
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
