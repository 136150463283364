// sass-lint:disable mixin-name-format
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'assets/stylesheets/base/layout';

.card-container-n-3 {
  @include gridStyle(1);

  @include media-breakpoint-up(xl) {
    @include gridStyle(3);
  }
}

.card-container-n-4 {
  @include gridStyle(1);

  @include media-breakpoint-up(sm) {
    @include gridStyle(2);
  }

  @include media-breakpoint-up(xl) {
    @include gridStyle(4);
  }
}

.card-container-n-5 {
  @include gridStyle(1);

  @include media-breakpoint-up(sm) {
    @include gridStyle(2);
  }

  @include media-breakpoint-up(xl) {
    @include gridStyle(5);
  }
}
