@import '../base/variables';

.p-timeline {
  .p-timeline-event-marker {
    background-color: $newBackground;
    border: 2px solid $gray3;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
  }

  .p-timeline-event-connector {
    background-color: $gray2;
  }

  &.p-timeline-disable-vertical-opposite {
    .p-timeline-event {
      .p-timeline-event-opposite {
        display: none;
      }
    }
  }

  &.p-timeline-vertical {
    .p-timeline-event-opposite {
      padding: 0 1rem;

      .disable-opposite {
        display: none;
      }
    }

    .p-timeline-event-content {
      padding: 0 1rem;
    }

    .p-timeline-event-connector {
      width: 2px;
    }
  }

  &.p-timeline-horizontal {
    .p-timeline-event-connector {
      height: 0.125rem;
    }
  }
}
