$customColors: $aqua $gray3 transparent transparent transparent transparent;

/* Default colors */
@for $i from 1 through length($customColors) {
  $color: nth($customColors, $i);

  .highcharts-color-#{$i - 1} {
    fill: $color;
    stroke: $gray6;
  }
}
