@import '../../assets/stylesheets/base/colors';
@import '../../assets/stylesheets/base/typography';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.active-saved-filters-text {
  color: $takedaRed;
}

.no-saved-filters {
  color: $gray1;
}

.saved-filters {
  color: $gray2;
  max-height: 25vh;
  overflow: auto;

  .filter-name {
    @include componentStyle('tag');
  }

  .saved-filter-name {
    // sass-lint:disable mixin-name-format
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 13%;
    }
  }

  .saved-filter-chip {
    background-color: $white;
    color: $gray2;
  }

  .action-btn-text {
    @include componentStyle('h4');
  }

  .invalid-filter {
    .p-chip-text {
      .filter-label {
        text-decoration: line-through;
      }
    }
  }
}
