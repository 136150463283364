@import '../../../base/colors';
@import '../../../base/typography';
@import '../base/variables';

.p-datepicker-trigger {
  background-color: transparent;

  .p-button {
    color: $black;
  }
}

.p-datepicker {
  @include componentStyle('btn');
  background: $componentWhite;
  border: $datepickerBoxBorder;
  padding: 0.5rem;

  &:not(.p-datepicker-inline) {
    border: 0 none;
  }

  .p-datepicker-header {
    margin: 0;
    padding: $datepickerHeaderPadding;

    .p-datepicker-prev {
      background: transparent;
      border: 0 none;
      color: $takedaRed;
    }

    .p-datepicker-next {
      background: transparent;
      border: 0 none;
      color: $takedaRed;
    }

    .p-datepicker-title {
      line-height: 2rem;

      .p-datepicker-month {
        @include componentStyle('btn');
        color: $black;
        margin-right: 0.3rem;
      }

      .p-datepicker-year {
        &.p-link {
          @include componentStyle('btn');
          color: $black;
        }
      }

      .p-datepicker-decade {
        span {
          @include componentStyle('btn');
          color: $black;
        }
      }
    }
  }

  table {
    width: fit-content;

    th {
      @include componentStyle('cap');
      border-bottom: 1px solid $gray1;
      border-top: 1px solid $gray1;
      color: $datepickerDaysColor;
      padding: $datepickerHeaderPadding;

      span {
        text-transform: uppercase;
      }
    }

    td {
      padding: 0.3rem;

      span {
        border: 2px solid $componentWhite;
        border-radius: 50%;
        color: $gray1;
        padding: 0.3rem;

        &.p-highlight {
          background-color: $takedaRed;
          border: 2px solid $takedaRedHover;
          color: $componentWhite;
        }

        &:focus {
          outline: 0 none;
          outline-offset: 0;
        }
      }
    }
  }

  .p-datepicker-buttonbar {
    border-top: $simpleBorder $datepickerButtonBarBorderColor;
    display: flex;
    justify-content: center;
  }

  .p-timepicker {
    padding: 0.5rem;

    button {
      background: transparent;
      border: 0 none;
      border-radius: 50%;
      color: $takedaRed;

      &:focus {
        outline: 0 none;
        outline-offset: 0;
      }

      &:last-child {
        margin-top: 0.2em;
      }
    }

    div {
      padding: 0 0.5rem;
    }
  }

  .p-monthpicker {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0.5rem 0;

    .p-monthpicker-month {
      border: $monthAndYearPickerBorder;
      margin: 0.1rem;
      padding: 0.1rem;

      &:hover {
        background-color: transparent;
        border: $datepickerMonthYearHoverBorder;
        color: $datepickerHoverTextColor;
      }

      &.p-highlight {
        background-color: transparent;
        border: $datepickerMonthYearHoverBorder;
        color: $datepickerHoverTextColor;
      }
    }
  }

  .p-yearpicker {
    align-content: center;
    display: flex;
    flex-flow: column wrap;
    margin: 0.35rem 0;

    .p-yearpicker-year {
      border: $monthAndYearPickerBorder;
      margin: 0.1rem;
      padding: 0.1rem;

      &:hover {
        background-color: transparent;
        border: $datepickerMonthYearHoverBorder;
        color: $datepickerHoverTextColor;
      }

      &.p-highlight {
        background-color: transparent;
        border: $datepickerMonthYearHoverBorder;
        color: $datepickerHoverTextColor;
      }
    }
  }

  .p-disabled {
    color: $gray3;
  }

  &.p-datepicker-timeonly {
    &.p-timepicker {
      border-top: 0 none;
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: $datepickerButtonBarBorderColor;
      padding: 0 0.5rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-left: 0 none;
        padding-right: 0;
      }
    }
  }
}
