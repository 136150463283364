// sass-lint:disable force-pseudo-nesting

@import '../base/variables';

.p-datatable {
  .p-paginator {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: $paginatorMargin;
  }

  .p-paginator-page,
  .p-paginator-page:hover,
  .p-paginator-prev,
  .p-paginator-prev:hover,
  .p-paginator-next,
  .p-paginator-next:hover,
  .p-paginator-current {
    background: $paginatorButtonsBackground;
    border: 0;
    color: $paginatorButtonsTextColor;
    cursor: pointer;
    max-width: $paginatorButtonMaxWidth;
    padding: $paginatorButtonPadding;

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          color: $takedaRed;
        }
      }
    }

    &.p-highlight {
      &:hover {
        background: $takedaRedHover;
        color: $white;
      }
    }
  }

  .p-paginator-prev,
  .p-paginator-prev:hover,
  .p-paginator-next,
  .p-paginator-next:hover {
    background: transparent;
  }

  .p-highlight {
    background: $paginatorActiveButtonBackground;
    border-radius: $noBorderRadius;
    color: $paginatorActiveButtonFontColor;
  }

  .p-paginator-last,
  .p-paginator-last:hover,
  .p-paginator-first,
  .p-paginator-first:hover {
    display: none;
  }
}
