// sass-lint:disable mixin-name-format
@import '../../assets/stylesheets/base/typography';
@import '../../assets/stylesheets/base/colors';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.compass-nav-holder {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

.compass-content {
  padding: calc(0.25rem + 95px) 0 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 1.5rem;
    padding-top: calc(0.5rem + 46px);
  }
}

.logout-btn {
  @include componentStyle('p');

  background-color: $white;
  border: 1px solid $takedaRed;
  color: $takedaRed;

  &:hover {
    background-color: $takedaRed20;
  }
}
