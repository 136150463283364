@import '../../../../../assets/stylesheets/base/colors';

.p-multiselect {
  @include componentStyle($component: 'p');
  background: $white;
  border: 0.0625rem solid $gray3;
  height: 2rem;
  line-height: 1.125rem;
  padding: 0.375rem 0.75rem;
  text-overflow: 'ellipsis';
  width: 10rem;

  &:not(.p-disabled) {
    &:hover {
      border: 0.0625rem solid $takedaRed;
    }
  }

  .p-multiselect-label {
    border: 0 none;
    outline: 0;

    &:enabled {
      &:focus {
        box-shadow: none;
        outline: 0 none;
      }
    }
  }
}

.p-multiselect-panel {
  border: 0.0625rem solid $gray3;
  margin-top: 1px;
  width: 10rem;

  &.wrap-options {
    .p-multiselect-items {
      .p-multiselect-item {
        text-wrap: wrap;
      }
    }
  }
}

.p-multiselect-tooltip {
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  margin-top: -2px;
}

.p-multiselect-header {
  background-color: $white;
  border-bottom: 0.05rem solid $gray3;
  color: $gray1;
  padding: 0.625rem;
  transition: box-shadow 0.2s;

  .p-checkbox {
    width: 100%;
  }

  .p-multiselect-close {
    display: none;
  }
}

.p-multiselect-items {
  background: $white;

  .p-multiselect-item {
    @include componentStyle($component: 'p');
    color: $gray1;
    max-width: 100%;
    padding: 0.625rem;
    transition: box-shadow 0.2s;
    width: 100%;

    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          background: $gray4;
        }
      }
    }

    .p-checkbox {
      margin-right: 1rem;
    }
  }
}

.p-multiselect-selectall-text {
  @include componentStyle($component: 'p');
  color: $gray1;
  margin-left: 1rem;
  transition: box-shadow 0.2s;
}
