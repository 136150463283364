// sass-lint:disable no-important

.highcharts-reset-zoom {
  @include componentStyle('sp');

  .highcharts-button-box {
    fill: $white;
    stroke: $takedaRed;
  }

  text {
    fill: $takedaRed !important;
  }

  &:hover {
    .highcharts-button-box {
      fill: $takedaRed20;
    }
  }
}
